import React, { FC } from 'react'
import styled from 'styled-components'

const StyledButton = styled.button`
    border: 2px solid #1c1f21;
    border-radius: 2px;
    background: none;
    width: 100%;
    padding: 1rem;
    font-size: 1.25rem;
    font-weight: 600;
    &:hover {
        border: 2px solid #1c1f21;
        background: #1c1f21;
        color: white;
    }
    &:disabled {
        cursor: not-allowed;
        background-color: rgb(51, 51, 51, 0.25);
        color: rgb(51, 51, 51, 0.75);
        border: 2px solid rgb(51, 51, 51, 0.75);
    }
`

interface DBBCButtonProps {
    label: string
}

export const DBBCButton: FC<React.ButtonHTMLAttributes<HTMLElement> & DBBCButtonProps> = ({
    onClick,
    label,
    ...restProps
}) => (
    <StyledButton onClick={onClick} {...restProps}>
        {label}
    </StyledButton>
)
