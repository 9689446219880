import React, { FC } from 'react'
import styled from 'styled-components'
import { useShopify } from '../hooks'
import { FaShoppingCart, FaTimes } from 'react-icons/fa'

const Navigation = styled.nav`
    position: sticky;
    top: 0;
    background-color: #1c1f21;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    height: 5rem;
    z-index: 214748364;
    & a {
        color: #eaebed;
        text-decoration: none;
    }
    & button {
        margin: 0;
        padding: 0;
        background: none;
        border: none;
        color: #eaebed;
        position: relative;
        & svg {
            width: 25px;
            height: 25px;
        }
        & span {
            position: absolute;
            background-color: #eaebed;
            border-radius: 50%;
            color: #1c1f21;
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            top: -10px;
            right: -10px;
            border: 2px solid #1c1f21;
            font-weight: bold;
        }
    }
`

export const Nav: FC = () => {
    const { cartStatus, closeCart, openCart, checkoutState } = useShopify()

    const handleToggleCart = () => {
        cartStatus ? closeCart() : openCart()
    }
    return (
        <Navigation>
            <a href="/">
                <h1 title="Dad Bod Board Crew">DadBodBoardCrew</h1>
            </a>
            <button onClick={() => handleToggleCart()}>
                {cartStatus ? (
                    <FaTimes />
                ) : (
                    <>
                        <FaShoppingCart />
                        {checkoutState.lineItems && checkoutState.lineItems.length > 0 && (
                            <span>{checkoutState.lineItems.length}</span>
                        )}
                    </>
                )}
            </button>
        </Navigation>
    )
}
