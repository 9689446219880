import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { Product } from 'shopify-buy'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import { useShopify } from '../../hooks'
import { DBBCButton } from '../DBBCButton'
import { Link } from 'react-router-dom'

interface ProductDetailsType extends Product {
    descriptionHtml: string
}

interface ProductDetailsProps {
    product: ProductDetailsType
}

const ProductContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    margin: 0rem auto;
    padding: 2rem 1rem;
    @media (max-width: 872px) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        &:last-of-type {
            border-bottom: none;
        }
    }
`

const ProductLeft = styled.div`
    max-width: 800px;
    min-width: 350px;
    flex: 1;
`

const ProductRight = styled.div`
    max-width: 350px;
    min-width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    & ul {
        list-style: none;
        padding: 0;
    }
`

const ProductTitle = styled.h2`
    font-size: 1.5rem;
    margin: 0;
`

const StyledCarousel = styled(Carousel)`
    .thumbs-wrapper {
        display: flex;
        justify-content: center;
    }
    .thumbs {
        padding: 0;
    }
    .carousel.carousel-slider .control-arrow {
        background: rgba(0, 0, 0, 0.1);
        top: 45%;
        bottom: 45%;
    }
`

const ProductOptionContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 1rem 0;
    & label {
        font-size: 1.25rem;
        min-width: 100px;
    }
    & input,
    & select {
        width: 100%;
        font-size: 1.25rem;
        flex: 1;
        padding: 1rem 0.5rem;
        background: none;
        border: 2px solid #1c1f21;
        border-radius: 2px;
        height: 61px;
    }
    & select {
        appearance: none;
        background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%0'><polygon points='0,0 100,0 50,50'/></svg>")
            no-repeat;
        background-size: 12px;
        background-position: calc(100% - 20px) center;
        background-repeat: no-repeat;
    }
`

const StyledHR = styled.hr`
    margin: 2rem 0;
`

const BackToShopLink = styled(Link)`
    border: 2px solid #1c1f21;
    border-radius: 2px;
    background: none;
    padding: 1rem;
    font-size: 1.25rem;
    font-weight: 600;
    text-decoration: none;
    color: inherit;
    display: flex;
    justify-content: center;
    max-width: 200px;
    margin: 2rem auto 1rem;
    &:hover {
        border: 2px solid #1c1f21;
        background: #1c1f21;
        color: white;
    }
    &:disabled {
        cursor: not-allowed;
        background-color: rgb(51, 51, 51, 0.25);
        color: rgb(51, 51, 51, 0.75);
        border: 2px solid rgb(51, 51, 51, 0.75);
    }
`

export const ProductDetails: FC<ProductDetailsProps> = ({ product }) => {
    console.log('ProductDetails', product)
    const { cartStatus, closeCart, openCart, checkoutState, addVariant } = useShopify()
    const defaultSize = product.variants && product.variants[0].id.toString()
    const [size, setSize] = useState('')
    const [quantity, setQuantity] = useState(1)

    function changeSize(sizeId: string, quantity: string) {
        openCart()
        if (sizeId === '') {
            sizeId = defaultSize
            const lineItemsToAdd = [{ variantId: sizeId, quantity: parseInt(quantity, 10) }]
            const checkoutId = checkoutState.id
            addVariant(checkoutId, lineItemsToAdd)
        } else {
            const lineItemsToAdd = [{ variantId: sizeId, quantity: parseInt(quantity, 10) }]
            const checkoutId = checkoutState.id
            addVariant(checkoutId, lineItemsToAdd)
        }
    }

    return (
        <>
            <ProductContainer>
                <ProductLeft>
                    <ProductTitle>{product.title}</ProductTitle>
                    <StyledCarousel
                        emulateTouch={true}
                        infiniteLoop={true}
                        showStatus={false}
                        showThumbs={false}
                        showIndicators={product.variants.length > 1}
                    >
                        {product.images &&
                            product.images.map((image: { id: any; src: string | undefined }, i: number) => {
                                return (
                                    <img
                                        key={Number(image.id) + i}
                                        src={image.src}
                                        alt={`${product.title} product shot`}
                                    />
                                )
                            })}
                    </StyledCarousel>
                </ProductLeft>
                <ProductRight>
                    {product.descriptionHtml.length > 0 && (
                        <div dangerouslySetInnerHTML={{ __html: product.descriptionHtml }} />
                    )}
                    {product.variants.length > 1 ? (
                        <ProductOptionContainer>
                            <label htmlFor={'prodOptions'}>Size</label>
                            <select
                                id="prodOptions"
                                name={size}
                                onChange={(e) => {
                                    setSize(e.target.value)
                                }}
                            >
                                {product.variants &&
                                    product.variants.map(
                                        (
                                            item: {
                                                id: { toString: () => string | number | readonly string[] | undefined }
                                                title: any
                                            },
                                            i: any
                                        ) => {
                                            return (
                                                <option
                                                    value={item.id.toString()}
                                                    key={item.title + i}
                                                >{`${item.title}`}</option>
                                            )
                                        }
                                    )}
                            </select>
                        </ProductOptionContainer>
                    ) : (
                        <>
                            {product.variants[0].title !== 'Default Title' && (
                                <ProductOptionContainer>
                                    <p>{product.variants[0].title}</p>
                                </ProductOptionContainer>
                            )}
                        </>
                    )}
                    <ProductOptionContainer>
                        <label htmlFor={'prodQuantity'}>Quantity</label>
                        <input
                            id="prodQuantity"
                            type="number"
                            min={1}
                            value={quantity}
                            onChange={(e) => {
                                setQuantity(Number(e.target.value))
                            }}
                        ></input>
                    </ProductOptionContainer>
                    <DBBCButton
                        onClick={() => changeSize(size, quantity.toString())}
                        label={`Add to Cart - $${product.variants && product.variants[0].price}`}
                    />
                </ProductRight>
            </ProductContainer>
            <StyledHR />
            <BackToShopLink to="/" onClick={() => cartStatus && closeCart()}>Back to Shop</BackToShopLink>
        </>
    )
}
