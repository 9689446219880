import React, { FC, useEffect } from 'react'
import { useShopify } from '../hooks'

type ShopifyWrapperProps = {
    children: React.ReactNode
}

export const ShopifyWrapper: FC<ShopifyWrapperProps> = ({ children }) => {
    const { createShop, createCheckout, fetchProducts } = useShopify()

    useEffect(() => {
        createShop()
        fetchProducts()
        createCheckout()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <>{children}</>
}
