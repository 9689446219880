import React, { FC, useEffect } from 'react'
import { ProductDetails } from '../components/shopify/ProductDetails'
import { useShopify } from '../hooks'

export const Product: FC = () => {
    const { fetchProduct, product } = useShopify()

    console.log('Product', product)

    useEffect(() => {
        const productId = window.location.pathname.split('/product/')[1]
        if (productId) {
            fetchProduct(productId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <>{Object.keys(product).length && <ProductDetails product={product} />}</>
}
