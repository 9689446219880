import React, { FC } from 'react'
import { Product } from 'shopify-buy'
import styled from 'styled-components'
import { ProductCard } from '../components/shopify/ProductCard'
import { useShopify } from '../hooks'

const ProductWrapper = styled.div`
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`

export const Products: FC = () => {
    const { products } = useShopify()

    if (products) {
        return (
            <ProductWrapper>
                {products
                    .slice(0)
                    .reverse()
                    .map((product: Product) => {
                        return <ProductCard key={product.id} product={product} />
                    })
                    .reverse()}
            </ProductWrapper>
        )
    } else {
        return <p>Loading...</p>
    }
}
