import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import styled from 'styled-components'
import { Nav } from '../components/Nav'
import { Cart } from '../components/shopify/Cart'
import { Product } from './Product'
import { Products } from './Products'
import { ShopifyWrapper } from './ShopifyWrapper'

const PageContainer = styled.div`
    padding: 1rem;
`

export const App = () => {
    const TRACKING_ID = 'UA-250209376-2'
    debugger
    ReactGA.initialize(TRACKING_ID)

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search)
    }, [])

    return (
        <ShopifyWrapper>
            <Nav />
            <Cart />
            <PageContainer>
                <Router>
                    <Routes>
                        <Route path="/" element={<Products />} />
                        <Route path="/product/:id" element={<Product />} />
                    </Routes>
                </Router>
            </PageContainer>
        </ShopifyWrapper>
    )
}
