import React, { FC, useEffect } from 'react'
import styled from 'styled-components'
import { useShopify } from '../../hooks'
import { DBBCButton } from '../DBBCButton'
import { LineItems } from './LineItems'

const CartContainer = styled.div<{ isCartOpen: boolean }>`
    z-index: 9999;
    position: fixed;
    top: 80px;
    right: 0;
    bottom: 0;
    width: 420px;
    max-width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    border-left: 1px solid #e5e5e5;
    transform: translateX(${({ isCartOpen }) => (isCartOpen ? '0' : '100%')});
    transition: transform 0.22s ease-in-out;

    @media (pointer: coarse) or (max-width: 1080px) {
        width: 100%;
    }
`

const CartHeader = styled.h2`
    margin: 0;
    padding: 1rem;
    border-bottom: 1px solid #e5e5e5;
    flex: 0 0 auto;
    display: inline-block;
`

const CartLineItems = styled.ul`
    flex: 1 0 auto;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow-y: scroll;
    height: calc(100vh - 297px);
`

const CartFooter = styled.div`
    padding: 20px;
    border-top: 1px solid #e5e5e5;
    flex: 0 0 auto;
`

const CartPricingContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

export const Cart: FC = () => {
    const { cartStatus, checkoutState, setCount } = useShopify()

    useEffect(() => {
        function getCount() {
            let lineItems = checkoutState.lineItems && checkoutState.lineItems.length > 0 ? checkoutState.lineItems : []
            let count = 0
            lineItems.forEach((item: { quantity: number }) => {
                count += item.quantity
                return count
            })

            setCount(count)
        }

        getCount()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartStatus, checkoutState])

    return (
        <CartContainer isCartOpen={cartStatus}>
            <CartHeader>Your cart</CartHeader>
            <CartLineItems>
                <LineItems />
            </CartLineItems>
            <CartFooter>
                <CartPricingContainer>
                    <p>Subtotal</p>
                    <p>$ {checkoutState.subtotalPrice}</p>
                </CartPricingContainer>
                <DBBCButton
                    onClick={() => window.location.replace(checkoutState.webUrl)}
                    label="Checkout"
                    disabled={!checkoutState.lineItems || checkoutState.lineItems.length === 0}
                />
            </CartFooter>
        </CartContainer>
    )
}
