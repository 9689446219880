import React, { FC } from 'react'
import { Item, LineItem } from 'shopify-buy'
import styled from 'styled-components'
import { useShopify } from '../../hooks'
import { FaTrash, FaPlus, FaMinus } from 'react-icons/fa'

const LineItemContainer = styled.li`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2rem 1.5rem;
    &:last-of-type {
        border-bottom: none;
    }
    & img {
        width: 150px;
        object-fit: contain;
        margin-right: 1rem;
    }
`

const LineItemInfo = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const LineItemTitleContainer = styled.div`
    & p {
        margin: 0;
    }
    & p:last-of-type {
        font-size: 0.8rem;
    }
`

const LineItemQuantityContainer = styled.div`
    display: flex;
    align-items: center;
    & p {
        margin: 0 1.2rem;
        font-size: 1.5rem;
    }
    & svg {
        width: 15px;
        height: 15px;
    }
`

const LineItemPriceContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const LineItems: FC = () => {
    const { checkoutState, updateQuantity, removeLineItem } = useShopify()

    const decrementQuantity = (lineItemId: LineItem['id'], lineItemQuantity: LineItem['quantity']) => {
        const checkoutId = checkoutState.id
        const updatedQuantity = lineItemQuantity - 1
        updateQuantity(lineItemId, updatedQuantity, checkoutId)
    }

    const incrementQuantity = (lineItemId: LineItem['id'], lineItemQuantity: LineItem['quantity']) => {
        const checkoutId = checkoutState.id
        const updatedQuantity = lineItemQuantity + 1
        updateQuantity(lineItemId, updatedQuantity, checkoutId)
    }

    const deleteLineItem = (lineItemId: LineItem['id']) => {
        const checkoutId = checkoutState.id
        removeLineItem(checkoutId, lineItemId)
    }

    return (
        <>
            {checkoutState.lineItems &&
                checkoutState.lineItems.map((lineItem: Item & LineItem, i: number) => {
                    return (
                        <LineItemContainer key={`${lineItem.title}` + i}>
                            {lineItem.variant.image ? (
                                <img src={lineItem.variant.image.src} alt={`${lineItem.title} product shot`} />
                            ) : null}
                            <LineItemInfo>
                                <LineItemTitleContainer>
                                    <p>{lineItem.title}</p>
                                    {lineItem.variant.title !== 'Default Title' && <p>{lineItem.variant.title}</p>}
                                </LineItemTitleContainer>
                                <LineItemQuantityContainer>
                                    <FaMinus onClick={() => decrementQuantity(lineItem.id, lineItem.quantity)} />
                                    <p>{lineItem.quantity}</p>
                                    <FaPlus onClick={() => incrementQuantity(lineItem.id, lineItem.quantity)} />
                                </LineItemQuantityContainer>
                                <LineItemPriceContainer>
                                    <p>$ {(lineItem.quantity * Number(lineItem.variant.price)).toFixed(2)}</p>
                                    <FaTrash onClick={() => deleteLineItem(lineItem.id)} />
                                </LineItemPriceContainer>
                            </LineItemInfo>
                        </LineItemContainer>
                    )
                })}
        </>
    )
}
